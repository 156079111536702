










import editMixin from "@/mixin/edit-mixin";
import editor from "@/components/_universal/global/editor.vue";
export default {
  mixins: [editMixin],
  components: {
    editor,
  },
  data() {
    return {
      content: null,
    };
  },
  methods: {
    updateHere(html) {
      this.content = html;
      this.updateField(
        "Description", //PropertyName
        html, //Property Value
        "Description", //Display Name,
        html, //Display Value
        "Description" //Data object Property Name
      );
    },
  },
  mounted() {},
  beforeDestroy() {},
};
